import request, { baseUrl } from "@/utils/request";

/*
 *@描述: 回显公告板信息
 *@参数: 无
 *@作者: 金涛
 *@日期: 2022-02-09 15:27:14
 */
export function getNotice() {
  return request({
    url: `${baseUrl.api}/notice/get`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}
