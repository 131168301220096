<template>
  <div class="container-detail">
    <template v-if="isFinsih">
      <span class="release-date u-flex u-row-right">
        {{ `${$t("releaseDate")}: ${params.publishDate}` }}
      </span>
      <p>{{ params.intro }}</p>
    </template>
  </div>
</template>
<script>
import { getNotice } from "@/api/bulletinBoardDetail";

export default {
  name: "",
  data() {
    return {
      isFinsih: false,
      params: {
        title: "", // 标题
        intro: "", // 简介
        publishDate: "", // 时间
        linkUrl: "", // 链接
        startDate: "", // 开始时间
        endDate: "", // 结束时间
      },
    };
  },
  created() {
    this.getKKtitle().then((title) => {
      document.title = title;
    });

    this.getNotice();
  },
  methods: {
    // 回显公告板详情
    getNotice() {
      getNotice().then((res) => {
        this.isFinsih = true;
        this.params = { ...this.params, ...res.data };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-detail {
  background: #fbf7f3;
  padding: 0.4rem 0.4rem 1.6rem;
  font-size: 0.28rem;
  font-weight: 400;
  text-align: justify;
  color: #988157;
  line-height: 0.48rem;
  min-height: 100%;

  .release-date {
    font-size: 0.24rem;
    margin-bottom: 0.3rem;
    color: #959392;
  }
}
</style>
